import incoming from '@/assets/sounds/sound_incoming.mp3';
import notification from '@/assets/sounds/sound_notification.mp3';
import store from '@/services/store';

const sounds = {
  incoming : new Audio(incoming),
  notification: new Audio(notification)
};

export default {
  tryPlaySound(){
    let sound = this.getSound("incoming");
    return new Promise(resolve => {
      let play = sound.play();
      if(play !== undefined){
        play.then(() => {
          sound.pause();
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          resolve(false);
        })
      }
      else{
        resolve(false)
      }
    })
  },
  getSound(name){
    return Object.assign(sounds[name],{
      soundIsPlaying: false,
      enableSound(){
        this.muted = false;
        this.currentTime = 0;
        this.loop = true;
        this.play();
        this.soundIsPlaying = true;
      },
      disableSound(){
        this.currentTime = 0;
        this.pause();
        this.soundIsPlaying = false;
      }
    });
  },
  meetingIsActive(){
    return store.getters['Socket/getMeeting'] ? true : false;
  },
  playSound(name){
    try {
      if(this.meetingIsActive()){
        return;
      }
      let sound = this.getSound(name);
      if(!sound.soundIsPlaying){
        sound.enableSound();
      }
    } catch (e) {
      console.log('@sound', e.toString())
    }
  },
  stopSound(name){
    try {
      let sound = this.getSound(name);
      sound.disableSound();
    } catch (e) {
      console.log('@sound', e.toString())
    }
  },
  stopSounds(){
    for(let name in sounds){
      this.stopSound(name);
    }
  }
}
