export default {
    namespaced: true,
    state: {
      data: {
        title: null,
        slogan: null,
        logoImagePath: null
      },
      isLoaded: null
    },
    mutations: {
      mutateData(state,{data, isLoaded}){
        state.data = Object.assign({},data || {});
        state.isLoaded = isLoaded;
      },
    },
    getters: {
      getData(state){
        return state.data;
      },
      isLoaded(state){
        return state.isLoaded;
      },
    },
    actions: {
      setData({commit}, data){
        return commit('mutateData', data);
      }
    }
};
