import store from '@/services/store';
import storage from '@/services/storage.browser.service';
const name = process.env.VUE_APP_BROWSER_STORAGE_AUTH_NAME;

export default {
  get(){
    return storage.local.get(name);
  },
  getTokens(){
    return store.getters['Auth/getTokens'] ;
  },
  hasAuth(){
    return store.getters['Auth/hasAuth'];
  },
  save(user, tokens){
    storage.local.set(name, JSON.stringify({user,tokens}));
    return store.dispatch('Auth/setAuth',{user,tokens});
  },

  updateUser(user){
    let {tokens} = JSON.parse(storage.local.get(name));
    storage.local.set(name, JSON.stringify({user,tokens}));
    return store.dispatch('Auth/setAuth',{user,tokens});
  },

  updateTokens(tokens){
    let {user} = JSON.parse(storage.local.get(name));
    storage.local.set(name, JSON.stringify({user,tokens}));
    return store.dispatch('Auth/setAuth',{user,tokens});
  },

  updateStore(user,tokens){
    return store.dispatch('Auth/setAuth',{user,tokens});
  }
}
