<template>
  <div class="d-flex flex-shrink-1 shadow topnav bg-white px-4 py-2">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div class="d-flex justify-content-between align-items-center">
        <!--
        <button class="btn btn-none btn-outline-none p-0 me-3" type="button">
          <i class="bi bi-list" style="font-size: 24px;"></i>
        </button>
      -->
        <div class="topnav-title" v-if="navbar">{{navbar.title}}</div>
      </div>

      <div class="d-flex justify-content-between align-items-center">

        <router-link v-if="nav.module !== 'dashboard' && queueCount > 0" :to="{ name: 'dashboard'}" class="btn btn-purple shadow position-relative d-flex justify-content-between align-items-center">
          <div class="spinner-grow spinner-grow-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          incoming calls
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{queueCount}}
            <span class="visually-hidden">incoming calls</span>
          </span>
        </router-link>

        <div class="dropdown d-none">
          <button class="btn btn-light dropdown-toggle p-2" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-telephone-plus-fill text-success pe-2"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
            <li><a class="dropdown-item" href="#"><i class="bi bi-telephone-plus-fill text-success pe-2"></i>Allow all calls</a></li>
            <li><a class="dropdown-item" href="#"><i class="bi bi-telephone-x-fill text-danger pe-2"></i>Don't allow any calls</a></li>
          </ul>
        </div>

        <div class="dropdown topnav-dd-user">
          <button class="btn btn-none btn-outline-none dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="position-relative d-inline-block">
              <img v-if="user.profileImageFilename" :src="user.profileImage.getImagePath()" class="avatar-wh border rounded-circle" crossOrigin="anonymous">
              <span v-else class="avatar-wh bg-secondary text-white rounded-circle d-flex justify-content-center align-items-center">{{ getInitials() }}</span>
            </span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><h6 class="dropdown-header">Settings</h6></li>
            <!--<li><a class="dropdown-item" href="#">Manage Widgets</a></li>-->
            <li v-if="user.owner === null">
              <router-link :to="{ name: 'account.subaccounts'}" class="dropdown-item">Manage Team</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'account.channels'}" class="dropdown-item">Channels</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'account.clients'}" class="dropdown-item">API Keys</router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li><h6 class="dropdown-header">Account settings</h6></li>
            <li>
              <router-link :to="{ name: 'account.details'}" class="dropdown-item">My Account</router-link>
            </li>
            <li><a class="dropdown-item d-none" href="#">Plans &amp; Billing</a></li>
            <li><button class="dropdown-item" @click="signOut()">Sign-out</button></li>
          </ul>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import storage from '@/services/storage.browser.service';
export default {
  props: ['title'],

  methods: {
    signOut(){
      this.$ws.socket().close();
      this.user.logout().then(() => {
        this.$store.dispatch('Auth/destroy').then(() => {
          storage.local.remove(process.env.VUE_APP_BROWSER_STORAGE_AUTH_NAME);
          this.$router.push({name: "login"});
        });
      });
    },

    getInitials(){
      let name = this.user.fullName;

      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials;
    },
  },

  computed: {
    user(){
      return this.$store.getters['Auth/getUser'];
    },
    nav(){
      return this.$store.getters['Navigation/getActive'];
    },
    navbar(){
      return this.nav.navbar;
    },
    queueCount(){
      return this.$store.getters['Socket/getQueue'].length;
    },

    whoami(){
      return this.$store.getters['Whoami/getData'];
    }
  }
}
</script>
