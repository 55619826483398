export default {
    namespaced: true,
    state: {
      active: {
        module: null,
        page: null,
        entity: null,
        navbar: {
          title: null
        },
        subpage: {
          name: null,
          entity: null,
          child: null
        }
      }
    },
    mutations: {
      mutateActive(state,data){
        state.active = Object.assign({
          subpage: {
            name: null,
            entity: null,
            child: null
          }
        },data);
      },
      mutateActiveSubpage(state,data){
        state.active.subpage = Object.assign({},data);
      },
      mutateOverrideActive(state, data){
        state.active = Object.assign({}, state.active, data);
      },
      mutateOverrideActiveSubpage(state, data){
        state.active.subpage = Object.assign({}, state.active.subpage, data);
      }
    },
    getters: {
      getActive(state){
        return state.active;
      },
    },
    actions: {
      setActive({commit}, data){
        return commit('mutateActive', data);
      },
      setActiveSubpage({commit},data){
        return commit('mutateActiveSubpage', data);
      },
      overrideActive({commit}, data){
        return commit('mutateOverrideActive', data);
      },
      overrideActiveSubpage({commit},data){
        return commit('mutateOverrideActiveSubpage', data);
        //return commit('updateActiveSubpage', Object.assign(getters['getActive'].subpage,data));
      }
    }
};
