<template>
  <template v-if="whoamiIsLoaded === true">
    <div v-if="hasAuth && user" class="d-flex w-100 h-100">
      <SideBar/>
      <PageContainer>
        <NavBar/>
        <WelcomeModal/>
        <router-view v-if="team"/>
      </PageContainer>
    </div>
    <div v-else class="d-flex h-100 w-100 justify-content-center align-items-center">
      <router-view/>
    </div>
  </template>
  <div v-if="whoamiIsLoaded === false" class="d-flex h-100 w-100 justify-content-center align-items-center">
    <i class="bi bi-emoji-angry text-secondary" style="font-size: 60px;"></i>
  </div>
  <div v-if="whoamiIsLoaded === null" class="d-flex h-100 w-100 justify-content-center align-items-center">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/SideBar';
import PageContainer from '@/components/PageContainer';
import NavBar from '@/components/NavBar';
import WelcomeModal from '@/components/WelcomeModal';
export default {
  components: {
    SideBar,
    PageContainer,
    NavBar,
    WelcomeModal
  },

  created(){
    this.$api.whoami().then(result => {
      this.$store.dispatch('Whoami/setData',{
        data: result.data,
        isLoaded: true
      });
      if(result.data.customAppCss){
        this.loadScript(process.env.VUE_APP_API_HOST+result.data.customAppCss, () => {
          console.log("custom css loaded");
        });
      }
    })
    .catch(() => {
      this.$store.dispatch('Whoami/setData',{
        data: null,
        isLoaded: false
      });
    })

  },

  methods: {
    loadScript (src, cb) {
      const style = document.createElement('link');
      style.rel = "stylesheet";
      style.href = src;
      style.crossOrigin = 'anonymous';
      document.querySelector('head').appendChild(style);
      style.addEventListener('load', cb);
    },
  },

  computed: {
    hasAuth(){
      return this.$store.getters['Auth/hasAuth']
    },
    user(){
      return this.$store.getters['Auth/getUser']
    },
    team(){
      return this.$store.getters['Socket/getTeam']
    },
    whoamiIsLoaded(){
      return this.$store.getters['Whoami/isLoaded']
    }
  },

  watch: {
    "hasAuth": function(value){
      if(value){
        this.$ws.connect();
      }
    }
  }
}
</script>
