import request from './../services/request.service';
//import Interface from './../services/api.interface';

const auth = {
  login(usernameOrEmail,password){
    return request('POST','auth/user',{
      data: {usernameOrEmail,password}
    });
  },
  refreshTokens({accessToken,refreshToken}){
    return request('GET',`auth/refresh-tokens`,{
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Authorization-Refresh-Token': refreshToken,
      }
    });
  }
}

export default auth
