import request from './request.service';

const Interface = function(conf, extend){
  const getPrimaryKey = function(){
    if(conf.primaryKey){
      return conf.primaryKey
    }
    return 'id';
  }

  const getBaseUrl = function(){
    return conf.basePath;
  }

  const model = function(entity){
    let entityExtend = {};
    if(conf.extend && conf.extend.entity && typeof conf.extend.entity === "function"){
      entityExtend = conf.extend.entity(entity, model);
    }
    return Object.assign({},{
      update(data){
        return request('PUT',`${getBaseUrl()}/${entity[getPrimaryKey()]}`,{data}).then(result => {
          if(conf.model){
            result.data = Object.assign({}, result.data, model(result.data));
          }
          return result;
        });
      },
      destroy(){
        return request('DELETE',`${getBaseUrl()}/${entity[getPrimaryKey()]}`);
      }
    }, entityExtend)
  }

  return Object.assign({},{
    create(data){
      return request('POST',getBaseUrl(),{data}).then(result => {
        if(conf.model){
          result.data = Object.assign({}, result.data, model(result.data));
        }
        return result;
      });
    },
    findAll(params){
      return request('GET',getBaseUrl(),{params}).then(result => {
        if(conf.model){
          result.data.rows = result.data.rows.map(item => {
            return Object.assign({},item, model(item));
          })
        }
        return result
      });
    },
    findOne(id){
      return request('GET',`${getBaseUrl()}/${id}`).then(result => {
        if(conf.model){
          result.data = Object.assign({}, result.data, model(result.data));
        }
        return result;
      });
    },
  },extend || {})
}

export default Interface
