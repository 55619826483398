<template>
  <div ref="modalWelcome" class="modal fade" id="modalWelcome" tabindex="-1" aria-labelledby="modalWelcomeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img v-if="whoami.logoImagePath" :src="getLogoImagePath()" class="brand-logo mb-3" crossOrigin="anonymous" style="width: 70px;">
          <div class="mb-3">Welcome back to {{whoami.title}}</div>

          <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sounds from '@/services/sounds.service';
export default {
  mounted(){
    sounds.tryPlaySound().then(can => {
      if(!can){
        let modal = new this.$bootstrap.Modal(this.$refs["modalWelcome"]);
        document.onreadystatechange = function () {
          modal.show();
        }

      }
    })
  },

  computed: {
    whoami(){
      return this.$store.getters['Whoami/getData'];
    }
  },

  methods: {
    getLogoImagePath(){
      return process.env.VUE_APP_API_HOST+this.whoami.logoImagePath;
    }
  }
}
</script>
