import axios from 'axios';
//import store from '@/services/store';
//import storage from '@/services/storage.browser.service';
import authStorage from '@/services/auth.storage.service';

axios.interceptors.request.use(async function (config) {
  if(config.headers['Authorization']){
    console.log("@axios interceptor continue, Authorization header is present");
    return config;
  }

  //let tokens = store.getters['Auth/getTokens'] || {};
  let tokens = authStorage.getTokens() || {};

  if(tokens.accessToken){
    let now = Math.floor(Date.now() / 1000);
    if(tokens.expires <= now){
      let response = await axios({
        url: `${process.env.VUE_APP_API_HOST}/auth/user/refresh-tokens`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${tokens.accessToken}`,
          'Authorization-Refresh-Token': tokens.refreshToken,
        }
      });

      if(response.data !== null){
        tokens = response.data.data.tokens;
        await authStorage.updateTokens(tokens);
        //let user = store.getters['Auth/getUser'];
        //storage.local.set(name, JSON.stringify({user,tokens}));
        //await store.dispatch('Auth/setAuth',{user,tokens});
      }
    }
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

const request = function(method, endpoint, extra){
  let {headers,...options} = extra || {};

  let data = Object.assign({
    url: `${process.env.VUE_APP_API_HOST}/${endpoint}`,
    method: method,
    headers: headers
  }, options);
  
  return axios(data)
    .then(response => {
      return response.data;
    })
}

const requestUrl = function(endpoint){
  return `${process.env.VUE_APP_API_HOST}/${endpoint}`;
}

export {requestUrl};

export default request
