import moment from 'moment';

const queueTypes = [
  {
    name: "calling",
    meetingType: "call",
    title: "Calling",
    text: {
      queue: "calling",
      conversation: "in a call"
    },
    total: 0,
  },
  {
    name: "appointment",
    meetingType: "appointment",
    title: "Appointments",
    text: {
      queue: "joined appointment",
      conversation: "in a meeting"
    },
    total: 0,
  },
  {
    name: "waitingRoom",
    meetingType: "call",
    title: "Waiting Room",
    text: {
      queue: "in waiting room",
      conversation: "in a call"
    },
    total: 0,
  }
];

export default {
  queue(item, team){
    let caller = null;
    let user = null;
    if(item.caller && item.caller.type === "user"){
      user = team.find(o => o.id === item.caller.user.id);
      if(user){
        caller = user.fullName;
      }
    }
    if(item.caller && item.caller.type === "anonym"){
      caller = "Visitor";
    }

    let date = moment(item.meeting.createdAt).format("YYYY-MM-DD HH:mm:ss");

    let channel = item.meeting.channel;
    let source = "internal";
    if(channel){
      source = channel.name;
    }

    return Object.assign(item, {
      formatted: {
        caller,
        date,
        type: queueTypes.find(o => o.meetingType === item.meeting.type),
        //fromNow: moment(date).fromNow(true),
        fromNow: date,
        source,
        widget: "none",
        user
      }
    });
  },

  queueToConversation(item, callee, joinCallWithDevice){
    return Object.assign(item,{
      callee,
      operations: {
        joinCallWithDevice
      }
    })
  },

  conversation(item, team, loggedUser){
    let callee = null;
    let user = null;
    if(item.callee && item.callee.type === "user"){
      if(loggedUser.id === item.callee.user.id){
        callee = `${loggedUser.fullName} (You)`;
      }
      else{
        user = team.find(o => o.id === item.callee.user.id);

        if(user){
          callee = user.fullName;
        }
      }
    }

    item.formatted.callee = callee;
    item.formatted.inConversationSince = moment().format("YYYY-MM-DD HH:mm:ss");
    item.formatted.fromNow = moment(item.formatted.inConversationSince).fromNow(true)

    return item;
  }
}
