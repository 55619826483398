import {user} from '@/services/rest-api';
export default {
    namespaced: true,
    state: {
      user: null,
      tokens: null
    },
    mutations: {
      updateUser(state,data){
        state.user = data === null ? null : Object.assign({},data, user(data));
      },
      updateTokens(state,data){
        state.tokens = data === null ? null : Object.assign({},data);
      }
    },
    getters: {
      getUser(state){
        return state.user;
      },
      getTokens(state){
        return state.tokens;
      },
      hasAuth(state){
        return state.user !== null && state.tokens !== null;
      },
      getAuth(state){
        return {
          user: state.user,
          tokens: state.tokens
        }
      }
    },
    actions: {
      setUser({commit},data){
        return commit('updateUser', data.user);
      },
      setAuth({commit}, data){
        return Promise.all([
          commit('updateUser', data.user),
          commit('updateTokens', data.tokens),
        ]);
      },

      destroy({commit}){
        return Promise.all([
          commit('updateUser', null),
          commit('updateTokens', null),
        ]);
      }
    }
};
