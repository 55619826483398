export default {
  namespaced: true,
  state: {
    statuses: [
      {
        id: "available",
        title: "available",
        classNameText: "text-success",
        classNameBubble: "bg-success",
      },
      {
        id: "busy",
        title: "busy",
        classNameText: "text-danger",
        classNameBubble: "bg-danger",
      },
      {
        id: "in-call",
        title: "in a call",
        classNameText: "text-danger",
        classNameBubble: "bg-danger",
      },
      {
        id: "in-meeting",
        title: "in a meeting",
        classNameText: "text-danger",
        classNameBubble: "bg-danger",
      },
      {
        id: "offline",
        title: "offline",
        classNameText: "text-secondary",
        classNameBubble: "bg-secondary",
      },
      {
        id: "calls-disabled",
        title: "calls disabled",
        classNameText: "text-warning",
        classNameBubble: "bg-warning",
      },
    ],
    team: null,
    inQueue: [],
    inConversation: [],
    upcomingEvents: [],
    statistics: [],
    meeting: null,
    notifications: []
  },
  mutations: {
    mutateTeam(state,data){
      state.team = Object.assign([],data);
    },
    mutateQueue(state,data){
      state.inQueue = Object.assign([],state.inQueue.concat(data));
    },

    mutateAddConversation(state, data){
      state.inConversation = Object.assign([],state.inConversation.concat(data));
    },
    mutateSetConversation(state, data){
      state.inConversation = Object.assign([],data);
    },

    mutateItemFromQueueToInConversation(state, data){
      state.inQueue = Object.assign([], state.inQueue.filter(o => o.meeting.id !== data.meeting.id));
      state.inConversation = Object.assign([],state.inConversation.concat(data));
    },

    mutateRemoveAllUserRelatedItems(state, data){
      state.inQueue = Object.assign(
        [],
        state.inQueue
          .filter(o => (o.caller.type === "user" && o.caller.user.id) !== data.id)
      );

      state.inConversation = Object.assign(
        [],
        state.inConversation
          .filter(o => (o.caller.type === "user" && o.caller.user.id) !== data.id)
          .filter(o => (o.callee.type === "user" && o.callee.user.id) !== data.id)
      )
    },

    mutateRemoveFromQueueByMeeting(state, data){
      state.inQueue = Object.assign(
        [],
        state.inQueue.filter(o => o.meeting.id !== data.meeting.id)
      );
    },

    mutateMeeting(state,data){
      state.meeting = data === null ? null : Object.assign(state.meeting || {}, data);
    },
    mutateAddNotification(state,data){
      data.id = Math.random();
      state.notifications = Object.assign([],state.notifications.concat(data));
    },
    mutateRemoveNotification(state,data){
      state.notifications = Object.assign([],state.notifications.filter(o => o.id !== data.id));
    },
  },
  getters: {
    getTeam(state){
      return state.team;
    },
    getStatuses(state){
      return state.statuses;
    },
    getQueue(state){
      return state.inQueue;
    },
    getInConversation(state){
      return state.inConversation;
    },
    getMeeting(state){
      return state.meeting;
    },
    getNotifications(state){
      return state.notifications;
    }
  },
  actions: {
    setTeam({commit}, data){
      return commit('mutateTeam', data);
    },
    addQueue({commit}, data){
      return commit('mutateQueue', data);
    },
    removeFromQueueByMeeting({commit}, data){
      return commit('mutateRemoveFromQueueByMeeting', data);
    },
    addConversation({commit}, data){
      return commit('mutateAddConversation', data);
    },
    setConversation({commit}, data){
      return commit('mutateSetConversation', data);
    },
    moveItemFromQueueToInConversation({commit}, data){
      return commit('mutateItemFromQueueToInConversation', data);
    },
    removeAllUserRelatedItems({commit}, data){
      return commit('mutateRemoveAllUserRelatedItems', data);
    },
    setMeeting({commit}, data){
      return commit('mutateMeeting', data);
    },
    addNotification({commit}, data){
      return commit('mutateAddNotification', data);
    },
    removeNotification({commit}, data){
      return commit('mutateRemoveNotification', data);
    },
  }
}
