import { createRouter, createWebHistory } from 'vue-router'
import auth from './middlewares/auth.middleware';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/DashboardView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/call-history',
    name: 'callHistory',
    component: () => import('@/views/CallHistory/MyCallHistoryView.vue')
  },
  {
    path: '/missed-calls',
    name: 'missed.calls',
    component: () => import('@/views/CallHistory/MissedVisitorsCallsView.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/Calendar/CalendarView.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/AnalyticsView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/Contacts/ContactsView.vue')
  },
  {
    path: '/agent/:agentId',
    name: 'agent',
    component: () => import('@/views/Agent/AgentRouterView.vue')
  },
  {
    path: '/meetings/:meetingId',
    name: 'meeting',
    component: () => import('@/views/Meetings/MeetingRouterView.vue')
  },
  /*
  {
    path: '/configuration',
    component: () => import('@/views/Configuration/ConfigurationRouterView.vue'),
    children: [
      {
        path: "",
        name: "configuration.overview",
        component: () => import('@/views/Configuration/OverviewView.vue'),
      },
      {
        path: "widgets",
        name: "configuration.widgets.list",
        component: () => import('@/views/Configuration/Widgets/WidgetsList.vue'),
      },
      {
        path: "availability",
        name: "configuration.availability",
        component: () => import('@/views/Configuration/AvailabilityView.vue'),
      },
    ]
  },
  */
  {
    path: '/account',
    component: () => import('@/views/Account/MyAccountRouterView'),
    children: [
      {
        path: "",
        name: 'account.details',
        component: () => import('@/views/Account/UserDetailView'),
      },
      {
        path: "edit",
        name: 'account.editAccount',
        component: () => import('@/views/Account/UserEditAccountView'),
      },
      {
        path: "change-password",
        name: 'account.changePassword',
        component: () => import('@/views/Account/UserChangePasswordView'),
      },
      {
        path: "change-email",
        name: 'account.changeEmail',
        component: () => import('@/views/Account/UserChangeEmailView'),
      },
      {
        path: "organizations",
        name: 'account.organizations',
        component: () => import('@/views/Account/UserOrganizationsView'),
      },
      {
        path: "remove-account",
        name: 'account.removeAccount',
        component: () => import('@/views/Account/UserRemoveAccountView'),
      },
      {
        path: "subaccounts",
        name: 'account.subaccounts',
        component: () => import('@/views/Account/UserSubaccountsView'),
      },
      {
        path: "channels",
        name: 'account.channels',
        component: () => import('@/views/Account/Channels/UserChannelsView'),
      },
      {
        path: "channels/:channelId",
        name: 'account.channels.channel',
        component: () => import('@/views/Account/Channels/UserChannelDetailsView'),
      },
      {
        path: "clients",
        name: 'account.clients',
        component: () => import('@/views/Account/Clients/UserApiKeysView'),
      },
    ]
  },
  {
    path: "/account/subaccounts/:userId",
    name: 'account.subaccounts.user',
    component: () => import('@/views/Account/SubaccountRouterView'),
    children: [
      {
        path: "",
        name: 'account.subaccounts.user.details',
        component: () => import('@/views/Account/UserDetailView'),
      },
      {
        path: "edit",
        name: 'account.subaccounts.user.editAccount',
        component: () => import('@/views/Account/UserEditAccountView'),
      },
      {
        path: "change-password",
        name: 'account.subaccounts.user.changePassword',
        component: () => import('@/views/Account/UserChangePasswordView'),
      },
      {
        path: "change-email",
        name: 'account.subaccounts.user.changeEmail',
        component: () => import('@/views/Account/UserChangeEmailView'),
      },
      {
        path: "remove-account",
        name: 'account.subaccounts.user.removeAccount',
        component: () => import('@/views/Account/UserRemoveAccountView'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => auth({to, from, next, router}));

export default router
