<template>
  <div class="vh-100 w-100 d-flex flex-column">
    <slot/>
  </div>
</template>

<script>
export default {
}
</script>
