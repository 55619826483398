import { createI18n } from 'vue-i18n/index';
import messages from '@/lang';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

export default i18n;
