import { createStore } from 'vuex'
import Navigation from './Navigation';
import Auth from './Auth';
import Whoami from './Whoami';
import Socket from './Socket';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Navigation: Navigation,
    Auth: Auth,
    Whoami: Whoami,
    Socket: Socket,
  }
})
