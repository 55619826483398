<template>
  <div class="vh-100 d-flex flex-column sidebar shadow">
    <div class="d-flex align-items-center justify-content-left sidebar-top text-white pt-3">
      <router-link :to="{ name: 'dashboard'}" class="sidebar-brand">
        <img v-if="whoami.logoImagePath" :src="getLogoImagePath()" class="brand-logo" crossOrigin="anonymous">
        <span class="d-flex flex-column justify-content-center align-items-left">
          <span class="brand-name">{{whoami.title}}</span>
          <span class="brand-section" v-if="whoami.slogan">{{whoami.slogan}}</span>
        </span>
      </router-link>
    </div>

    <MeetingInterface v-if="meeting" :meeting="meeting"/>

    <div class="d-flex align-items-start justify-content-start">
      <ul class="nav nav-pills flex-column mb-auto w-100 sidebar-menu">
        <li>
          <router-link :to="{ name: 'dashboard'}" :class="getModuleClass('dashboard')">
            <i class="bi bi-broadcast-pin"></i>
            Live Board
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'missed.calls'}" :class="getModuleClass('missed.calls')">
            <i class="bi bi-telephone-x-fill"></i>
            Missed Calls
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'callHistory'}" :class="getModuleClass('callHistory')">
            <i class="bi bi-telephone-fill"></i>
            Call History
          </router-link>
        </li>
        <li class="d-none">
          <router-link :to="{ name: 'analytics'}" :class="getModuleClass('analytics')">
            <i class="bi bi-pie-chart-fill"></i>
            Analytics
          </router-link>
        </li>
        <li class="d-none">
          <router-link :to="{ name: 'calendar'}" :class="getModuleClass('calendar')">
            <i class="bi bi-calendar2-week-fill"></i>
            Calendar
          </router-link>
        </li>
        <li class="d-none">
          <router-link :to="{ name: 'contacts'}" :class="getModuleClass('contacts')">
            <i class="bi bi-people-fill"></i>
            Contacts
          </router-link>
        </li>
      </ul>
    </div>
    <Team v-if="showTeam"/>
    <!--
    <div class="p-3 d-none">
      <router-link :to="{ name: 'configuration.overview'}" :class="'btn btn-sidebar-settings p-2 w-100 '+(nav.module === 'configuration' ? 'active' : '')">
        <i class="bi bi-gear me-2"></i>
        Configuration
      </router-link>
    </div>
  -->
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
export default {
  data(){
    return {
      showTeam: true
    }
  },
  components: {
    Team: defineAsyncComponent(() => import('./TeamMenu')),
    MeetingInterface: defineAsyncComponent(() => import('@/components/meet/MeetingInterface')),
  },
  methods: {
    getModuleClass(name){
      let isActive = this.nav.module === name;
      if(isActive){
        return 'nav-link active btn-purple btn-outline-none';
      }
      return 'nav-link';
    },

    getLogoImagePath(){
      return process.env.VUE_APP_API_HOST+this.whoami.logoImagePath;
    }
  },

  computed: {
    nav(){
      return this.$store.getters['Navigation/getActive'];
    },
    whoami(){
      return this.$store.getters['Whoami/getData'];
    },
    meeting(){
      return this.$store.getters['Socket/getMeeting'];
    },
  },
}
</script>
