import request from './../services/request.service';
//import Interface from './../services/api.interface';

const metrics = {
  meetings(params){
    return request('GET','metrics/meetings',{params})
  },
  missedCalls(params){
    return request('GET','metrics/missed-calls',{params})
  }
}

export default metrics
