import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

import router from './router';
app.use(store);

import store from './services/store';
app.use(router);

import i18n from './services/i18n.service';
app.use(i18n);

import bootstrap from './services/bootstrap.service';
app.use(bootstrap);

import api from './services/rest-api';
app.use(api);

import ws from './services/ws.service';
app.use(ws);

import "./assets/style.css";

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
app.use(VCalendar, {})


app.mount('#app')
