import io from 'socket.io-client';
import storage from './auth.storage.service';
import store from '@/services/store';
import sounds from '@/services/sounds.service';
import liveEventsParser from '@/shared/live.events.parser';
const ws = {
  _socket: null,
  socket(){
    if(!this._socket){
      this.connect();
    }

    return this._socket;
  },
  connect(){
    this._socket = io(process.env.VUE_APP_WS_HOST,{
      transports: ['websocket','polling'],
      secure: true,
      auth: {
        user: storage.getTokens()
      }
    })

    this.registerEvents();
  },
  registerEvents(){
    this._socket.on("connect",() => {
      console.log("-----------> @connect", this._socket.id)
    })

    this._socket.on("live-events",(data) => {
      console.log("-----------> @live-events", data);
      store.dispatch('Socket/addQueue',data.inQueue);
      if(data.inQueue.filter(o => o.meeting.type === "call").length > 0){
        sounds.playSound('incoming');
      }

      if(data.inConversation.length > 0){
        let team = store.getters['Socket/getTeam'];

        store.dispatch('Socket/setConversation',data.inConversation.map(({meeting, caller, callee}) => {
          let q = liveEventsParser.queue({meeting,caller},team);
          return liveEventsParser.queueToConversation(q, callee, null);
        }));
      }
    })

    this._socket.on("user-online-status",(data) => {
      console.log("----------->@user-online-status",data);

      let team = store.getters['Socket/getTeam'];
      let statuses = store.getters['Socket/getStatuses'];
      if(team !== null && team.length > 0){
        let agent = team.find(o => o.id === data.id);
        if(agent){
          let status = statuses.find(o => o.id === data.status);

          agent.status = status ? status : statuses.find(o => o.id === "offline");
          agent.socketId = data.socketId;
        }
      }

      if(["available","offline"].indexOf(data.status) !== -1){
        store.dispatch('Socket/removeAllUserRelatedItems', data).then(() => {
          let queue = store.getters['Socket/getQueue'];
          if(queue.length > 0){
            sounds.playSound('incoming');
          }
          else{
            sounds.stopSounds();
          }
        })
      }

      if(data.conversation){
        let meeting = data.conversation.meeting;
        let loggedUser = store.getters['Auth/getUser'];
        let callee = data.conversation.callee;
        let caller = data.conversation.caller;
        let team = store.getters['Socket/getTeam'];

        let q = liveEventsParser.queue({meeting,caller},team);
        let qc = liveEventsParser.queueToConversation(q, callee, null);

        //meeting.type === "call" &&
        if(callee.user && caller.user && callee.user.id !== loggedUser.id){
          store.dispatch('Socket/addConversation',qc);
        }
        else{
          store.dispatch('Socket/moveItemFromQueueToInConversation', qc).then(() => {
            let queue = store.getters['Socket/getQueue'];
            if(queue.length > 0){
              sounds.playSound('incoming');
            }
            else{
              sounds.stopSounds();
            }
          })
        }
      }
    });

    this._socket.on('calling', data => {
      console.log("-----------> @calling", data)
      store.dispatch('Socket/addQueue',data);
      sounds.playSound('incoming');
    })

    this._socket.on('notification', data => {
      console.log("-----------> @notification", data)
      store.dispatch('Socket/addNotification',data);
    })

    this._socket.on('call-closed', data => {
      console.log("-----------> @call-closed", data)
      store.dispatch('Socket/removeFromQueueByMeeting', data).then(() => {
        let queue = store.getters['Socket/getQueue'];
        if(queue.length > 0){
          sounds.playSound('incoming');
        }
        else{
          sounds.stopSounds();
        }
      })
    })


  },

  sendNotification(to, data){
    return this._socket.emit('notification',{to,data});
  },

  placeCall(data){
    return this._socket.emit('place-call',data);
  },

  takeCall(data){
    sounds.stopSounds();
    return this._socket.emit('take-call',data);
  },
  closeCall(data){
    return this._socket.emit('close-call',data);
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$ws = ws;
  }
}
