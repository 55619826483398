import user from './collections/user.collection';
import auth from './collections/auth.collection';
import intl from './collections/intl.collection';
import whoami from './collections/whoami.collection';
import metrics from './collections/metrics.collection';

export {user,auth,intl,whoami,metrics};

export default {
  install: (app) => {
    app.config.globalProperties.$api = {
      user,auth,intl,whoami,metrics
    };
  }
}
