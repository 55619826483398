import request,{requestUrl} from './../services/request.service';
import Interface from './../services/api.interface';

const user = function(usr){
  return {
    logout(){
      return request('DELETE','me/sessions')
    },
    profileImage: {
      getImagePath(){
        return requestUrl(`public/images/${usr.realmId}/users/${usr.id}/${usr.profileImageFilename}`);
      },
      getUploadUrl(){
        return requestUrl(`me/profile-image`);
      },
      destroy(){
        return request('DELETE',`me/profile-image`);
      }
    },
    update: {
      profile(data){
        return request('PUT',`me/profile`,{data})
          .then(result => {
            result.data = Object.assign({}, result.data, user(result.data));
            return result;
          });
      },
      email(data){
        return request('PUT',`me/email`,{data})
          .then(result => {
            result.data = Object.assign({}, result.data, user(result.data));
            return result;
          });
      },
      password(data){
        return request('PUT',`me/password`,{data});
      },
      /*
      destroy(data){
        return request('DELETE','user/close-account',{data});
      },
      */
    },

    organizations: new Interface({
      basePath: 'organizations',
      model: "organization",
    }),
    clients: new Interface({
      basePath: 'clients',
      model: "client",
    }),
    meetings: new Interface({
      basePath: 'meetings',
      model: "meeting"
    }),
    visitorMeetings: new Interface({
      basePath: 'meetings/visitors',
      model: "meeting",
    }),
    subaccounts: new Interface({
      basePath: `subaccounts`,
      model: "user",
      extend: {
        entity: function(subacc, model){
          return {
            update: {
              profile(data){
                return request('PUT',`subaccounts/${subacc.id}/profile`,{data})
                  .then(result => {
                    result.data = Object.assign({}, result.data, model(result.data));
                    return result;
                  });
              },
              email(data){
                return request('PUT',`subaccounts/${subacc.id}/email`,{data})
                  .then(result => {
                    result.data = Object.assign({}, result.data, model(result.data));
                    return result;
                  });
              },
              password(data){
                return request('PUT',`subaccounts/${subacc.id}/password`,{data});
              },
            },
            profileImage: {
              getImagePath(){
                return requestUrl(`public/images/users/${subacc.id}/${subacc.profileImageFilename}`);
              },
              getUploadUrl(){
                return requestUrl(`subaccounts/${subacc.id}/profile-image`);
              },
              destroy(){
                return request('DELETE',`subaccounts/${subacc.id}/profile-image`);
              }
            },
          }
        }
      }
    }),
    myGroup: new Interface({
      basePath: 'subaccounts/group',
      model: "user",
      extend: {
        entity: function(gru){
          return {
            profileImage: {
              getImagePath(){
                return requestUrl(`public/images/${gru.realmId}/users/${gru.id}/${gru.profileImageFilename}`);
              },
            },
            meetings: {
              findAll(params){
                return request('GET',`agents/${gru.id}/meetings`,{params});
              }
            }
          }
        }
      }
    }),
    myGroupOnlineTeam(){
      return request('GET','agents/live');
    },
    channels: new Interface({
      basePath: 'channels',
      model: "channel",
      extend: {
        entity: function(chn){
          return {
            agents: {
              assign(users){
                return request('PUT',`channels/${chn.id}/users`,{
                  data: {users}
                });
              },
              unassign(users){
                return request('DELETE',`channels/${chn.id}/users`,{
                  data: {users}
                });
              },
            }
          }
        }
      }
    })
  }
};

export default user
