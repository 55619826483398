const storage = {
  local: {
    get: (name) => {
      return localStorage.getItem(name)
    },
    has: (name) => {
      return localStorage.getItem(name) ? true : false;
    },
    set: (name,value) => {
      return localStorage.setItem(name, value);
    },
    remove: (name) => {
      return localStorage.removeItem(name);
    }
  }
};

export default storage;
