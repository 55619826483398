//import store from '@/services/store';
//import storage from '@/services/storage.browser.service';
import api from '@/services/rest-api';
import authStorage from '@/services/auth.storage.service';

//const name = process.env.VUE_APP_BROWSER_STORAGE_AUTH_NAME;
const stop = function(next){
  //storage.local.remove(name);
  return next({name: "login"});
}

export default async function auth({next, to, router}) {
  console.log("@middleware: auth")
  if(to.name === 'login'){
    return next();
  }
  //let auth = storage.local.get(name);
  let auth = authStorage.get(name);
  if(auth !== null){
    try {
      let {user, tokens} = JSON.parse(auth);

      let now = Math.floor(Date.now() / 1000);
      if(tokens.expires <= now){
        return api.auth.refreshTokens(tokens)
          .then(response => {
            if(response.data !== null){
              tokens = response.data.tokens;
            }

            //storage.local.set(name, JSON.stringify({user,tokens}));
            return authStorage.save(user,tokens).then(() => {
              next();
            });
            /*
            return store.dispatch('Auth/setAuth',{user,tokens}).then(() => {
              return next();
            })
            */
          })
          .catch(() => {
            return stop(next,router)
          })
      }
      else{
        //if(!store.getters['Auth/hasAuth']){
        if(!authStorage.hasAuth()){
          return authStorage.updateStore(user, tokens).then(() => {
            return next();
          })
          /*
          return store.dispatch('Auth/setAuth',{user,tokens}).then(() => {
            return next();
          })
          */
        }
        else{
          return next();
        }
      }

    } catch (e) {
      return stop(next,router)
    }
  }
  else{
    return stop(next,router)
  }
}
